<template>
  <v-row>
    <v-col class="flex-centered p-0">
      <!-- start:: Calendar -->
      <div id="calendar">
        <!-- start:: Loader -->
        <div style="min-height: 10px" class="d-flex align-items-end">
          <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
        </div>
        <!-- end:: Loader -->

        <!-- begin:: Calendar -->
        <v-card>
          <template>
            <v-row>
              <v-col>
                <!-- start:: Toolbar -->
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      outlined
                      class="mr-4"
                      :color="isTodayInContext ? 'primary' : 'grey darken-2'"
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>

                      <!-- start:: Day/Week/Month View Dropdown -->
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <!-- end:: Day/Week/Month View Dropdown -->
                    </v-menu>

                    <!-- start:: Days Selector -->
                    <v-menu v-if="type !== 'day'" bottom right :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="grey darken-2" class="ml-1" v-bind="attrs" v-on="on">
                          <span>Days</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>

                      <DaysSelector v-model="weekdays" @input="handleWeekdaysInput" />
                    </v-menu>
                    <!-- end:: Days Selector -->
                  </v-toolbar>
                </v-sheet>
                <!-- end:: Toolbar -->

                <!-- begin:: Calendar Canvas -->
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    :weekdays="weekdays"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                  ></v-calendar>

                  <!-- begin:: Selected Event Modal -->
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    max-width="600px"
                    offset-x
                  >
                    <!-- start:: Modal Toolbar -->
                    <v-toolbar v-if="selectedEvent.job" :color="selectedEvent.color" dark>
                      <v-toolbar-title v-if="selectedEvent.job.Customer.Name">
                        {{ selectedEvent.job.Customer.Name }}
                      </v-toolbar-title>

                      <span class="mx-1"></span>

                      <UpdateJobDialog :job="selectedEvent.job" @refresh-jobs="selectedOpen = false" />

                      <v-spacer></v-spacer>

                      <v-btn class="m-0" icon small @click="selectedOpen = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <!-- end:: Modal Toolbar -->

                    <v-card
                      v-if="selectedElement"
                      class="selected-event-card no-padding"
                      color="grey lighten-4"
                      flat
                      tile
                    >
                      <!-- start:: Modal Content -->
                      <v-card-text>
                        <!-- start:: Start/End Time, Completed, and Images -->
                        <v-row
                          id="start-end-time-completed"
                          v-if="selectedEvent.start || selectedEvent.end || selectedEvent.job.Completed"
                        >
                          <v-col>
                            <div v-if="selectedEvent.job.JobSite">
                              <b v-if="selectedEvent.job.JobSite.Name">
                                {{ selectedEvent.job.JobSite.Name }}
                              </b>
                              <br />
                            </div>

                            {{ selectedEvent.start | moment }} - {{ selectedEvent.end | moment }}

                            <div id="is-completed">
                              <span v-if="selectedEvent.job.Completed" class="text-success">
                                <b>Complete</b>
                                <v-icon class="ml-1 mb-1" color="green" small>mdi-check</v-icon>
                              </span>
                              <span
                                v-if="!selectedEvent.job.Completed && !selectedEvent.job.Canceled"
                                class="text-danger"
                              >
                                <b>Incomplete</b>
                              </span>
                              <span v-if="selectedEvent.job.Canceled" class="text-warning">
                                <b>Canceled</b>
                              </span>
                            </div>

                            <!-- start:: Images Dialog -->
                            <div id="images" v-if="selectedEvent.job.Images" class="text-right">
                              <ImagesDialog
                                v-if="selectedEvent.job.Images.length"
                                :job="selectedEvent.job"
                              />
                            </div>
                            <!-- end:: Images Dialog -->
                          </v-col>
                        </v-row>
                        <!-- end:: Start/End Time, Completed, and Images -->

                        <hr
                          v-if="
                            selectedEvent.job.Material ||
                            selectedEvent.job.Quantity ||
                            selectedEvent.job.Applications
                          "
                        />

                        <!-- start:: Material, Quantity, Applications -->
                        <v-row
                          v-if="
                            selectedEvent.job.Material ||
                            selectedEvent.job.Quantity ||
                            selectedEvent.job.Applications
                          "
                        >
                          <v-col>
                            <div id="material" v-if="selectedEvent.job.Material">
                              <b>Material:</b> {{ selectedEvent.job.Material.Name }}
                            </div>

                            <div id="quantity" v-if="selectedEvent.job.Quantity">
                              <b>Quantity:</b> {{ selectedEvent.job.Quantity.Name }}
                              {{ selectedEvent.job.QuantityType }}
                            </div>

                            <div id="applications" v-if="selectedEvent.job.Applications">
                              <b>Applications:</b>
                              <span
                                v-for="(application, index) in selectedEvent.job.Applications"
                                :key="index"
                              >
                                {{ application.Name
                                }}<span v-if="index + 1 < selectedEvent.job.Applications.length"
                                  >,
                                </span>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                        <!-- end:: Material, Quantity, Applications -->

                        <hr
                          v-if="selectedEvent.job.JobSiteDetails || selectedEvent.job.DeliveryDetails"
                        />

                        <!-- start:: Jobsite and Delivery Details -->
                        <v-row
                          v-if="selectedEvent.job.JobSiteDetails || selectedEvent.job.DeliveryDetails"
                        >
                          <v-col id="jobsite-details" v-if="selectedEvent.job.JobSiteDetails">
                            <h6>Jobsite Details</h6>

                            <div v-if="selectedEvent.job.JobSiteDetails.PO">
                              <b>PO:</b> {{ selectedEvent.job.JobSiteDetails.PO }}
                            </div>

                            <div v-if="selectedEvent.job.JobSiteDetails.Municipal">
                              <b>Municipal:</b> {{ selectedEvent.job.JobSiteDetails.Municipal }}
                            </div>

                            <div v-if="selectedEvent.job.JobSiteDetails.Block">
                              <b>Block:</b> {{ selectedEvent.job.JobSiteDetails.Block }}
                            </div>

                            <div v-if="selectedEvent.job.JobSiteDetails.Lot">
                              <b>Lot:</b> {{ selectedEvent.job.JobSiteDetails.Lot }}
                            </div>

                            <div v-if="selectedEvent.job.JobSiteDetails.Unit">
                              <b>Unit:</b> {{ selectedEvent.job.JobSiteDetails.Unit }}
                            </div>
                          </v-col>

                          <v-col id="delivery-details" v-if="selectedEvent.job.DeliveryDetails">
                            <h6>Delivery Details</h6>

                            <div v-if="selectedEvent.job.DeliveryDetails.Price">
                              <b>Price:</b> ${{ selectedEvent.job.DeliveryDetails.Price }}
                            </div>

                            <div v-if="selectedEvent.job.DeliveryDetails.PriceType">
                              <b>Price Type:</b> {{ selectedEvent.job.DeliveryDetails.PriceType }}
                            </div>

                            <div v-if="selectedEvent.job.DeliveryDetails.Notes">
                              <b>Driver Details:</b> {{ selectedEvent.job.DeliveryDetails.Notes }}
                            </div>
                          </v-col>
                        </v-row>
                        <!-- end:: Jobsite and Delivery Details -->

                        <hr v-if="selectedEvent.job.Drivers" />

                        <!-- start:: Drivers -->
                        <v-row id="Drivers" v-if="selectedEvent.job.Drivers">
                          <v-col>
                            <h6>Assigned Drivers</h6>

                            <div class="d-flex flex-wrap justify-content-start mt-3">
                              <v-chip
                                v-for="driver in selectedEvent.job.Drivers"
                                :key="driver.userId"
                                class="m-1"
                              >
                                {{ driver.FirstName }} {{ driver.LastName }}
                              </v-chip>
                            </div>
                          </v-col>
                        </v-row>
                        <!-- end:: Drivers -->
                      </v-card-text>
                      <!-- end:: Modal Content -->

                      <!-- start:: ModalActions -->
                      <v-card-actions>
                        <ExportJob :job="selectedEvent.job" />

                        <v-spacer></v-spacer>
                        <v-btn text @click="selectedOpen = false">Close</v-btn>
                      </v-card-actions>
                      <!-- end:: Actions -->
                    </v-card>
                  </v-menu>
                  <!-- end:: Selected Event Modal -->
                </v-sheet>
                <!-- end:: Calendar Canvas -->
              </v-col>
            </v-row>
          </template>
        </v-card>
      </div>
      <!-- end:: Calendar -->
    </v-col>
  </v-row>
</template>

<script>
// Vue
import Vue from "vue";
import { mapGetters } from "vuex";
// moment.js
import moment from "moment";
// Services
import JobsService from "../dispatch/jobs/Jobs.service";
// Components
import UpdateJobDialog from "@/views/dispatch/jobs/job-dashboard/_dialogs/UpdateJobDialog.vue";
import ImagesDialog from "@/views/calendar/ImagesDialog.vue";
import ExportJob from "@/core/components/content/ExportJob.vue";
import DaysSelector from "@/core/components/content/DaysSelector.vue";
import { allDaysOfWeek } from "@/core/_constants/daysOfWeek.constant";

export default Vue.extend({
  name: "Calendar",
  components: { UpdateJobDialog, ImagesDialog, ExportJob, DaysSelector },

  data() {
    return {
      isLoading: false,

      lastStart: "",
      lastEnd: "",

      // ! Calendar props
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day"
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      weekdays: allDaysOfWeek
    };
  },

  mounted() {
    this.$refs.calendar.checkChange();
    if (this.currentUser.client.WorkingDays) {
      this.weekdays = this.currentUser.client.WorkingDays.sort();
    }
  },

  methods: {
    getCalendarEvents(start, end) {
      this.isLoading = true;

      // Store the start and end date ranges for calendar refreshes (triggered from state)
      this.lastStart = start;
      this.lastEnd = end;

      JobsService.getJobsDateRange(this.currentUser.clientId, start, end, true, true, true).then(
        (data) => {
          if (!data) return;

          const events = [];
          data.forEach((element) => {
            if (!element.DeliveryDetails) return;

            // ! Get the end date; if the Job has one, use it. If not, set the end date to 1 hour after start.
            let endDate;
            if (element.DeliveryDetails.EstimateEndDate) {
              endDate = new Date(element.DeliveryDetails.EstimateEndDate.seconds * 1000);
            } else {
              endDate = moment(new Date(element.DeliveryDetails.DeliveryDate.seconds * 1000))
                .add(1, "hours")
                .toDate();
            }

            // Set modal toolbar color
            let color = "indigo";
            if (this.isOverdueJob(element.DeliveryDetails.DeliveryDate)) color = "red";
            if (element.Canceled) color = "orange";
            if (element.Completed) color = "green";

            events.push({
              job: element, // Calendar modal metadata
              name: element.Customer?.Name ?? "", // This is what's displayed on the actual calendar

              // Convert start and end times from UNIX timestamp
              start: new Date(element.DeliveryDetails?.DeliveryDate?.seconds * 1000) ?? null,
              end: endDate,

              color: color,
              timed: true
            });
          });

          this.events = events;
          this.isLoading = false;
        }
      );
    },

    /**
     * ! This updates the Calendar modal re-render for the "Month" selection.
     * ! Do not remove until a fix is implemented.
     */
    refreshMonth() {
      if (this.type === "month") {
        this.type = "day";
        setTimeout(() => {
          this.type = "month";
        }, 500);
      }
    },

    handleWeekdaysInput() {
      if (this.weekdays.length === 0) {
        this.weekdays = allDaysOfWeek;
      }

      this.weekdays.sort();
    },

    // ! start:: Calendar methods
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = "";
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    /**
     * Updates the events that are rendered on the calendar
     */
    updateRange({ start, end }) {
      let startDate = start;
      let endDate = end;

      // Convert the Calendar's start and end dates into Date objs
      if (start.date) startDate = new Date(start.year, start.month - 1, start.day - 1, 0, 0);

      if (end.date) endDate = new Date(end.year, end.month - 1, end.day, 23, 59);

      this.getCalendarEvents(startDate, endDate);
    },
    // ! end:: Calendar methods

    /**
     * Checks if a date is in the past
     */
    isOverdueJob(date) {
      const testDate = moment(date.toDate());
      const currentDate = moment(new Date());
      return testDate < currentDate;
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      updateCalendar: "updateCalendar",
      activeJobs: "activeJobs"
    }),

    /**
     * Determines if the currently focused date matches today's calendar context.
     * @returns True if the calendar's current date matches today's day/week/month, False otherwise.
     */
    isTodayInContext() {
      if (this.focus === "") return true;

      const dateScheduler = moment(this.focus);
      return dateScheduler.isSame(moment.now(), this.type);
    }
  },

  watch: {
    updateCalendar() {
      if (this.updateCalendar) {
        this.getCalendarEvents(this.lastStart, this.lastEnd);
        this.$store.dispatch("SET_UPDATE_CALENDAR", false);
        this.refreshMonth();
      }
    },

    activeJobs() {
      this.getCalendarEvents(this.lastStart, this.lastEnd);
    }
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm A");
    }
  }
});
</script>

<style lang="scss" scoped>
#calendar {
  width: 1200px;
  height: 700px;
}

.event {
  padding: 0 !important;
}
</style>
